// Components (.vue)
import ChartBar  from '@/Components/Global/BasicChart/Components/ChartBar/template.vue'
import ChartHBar from '@/Components/Global/BasicChart/Components/ChartHBar/template.vue'
import ChartLine from '@/Components/Global/BasicChart/Components/ChartLine/template.vue'
import ChartPie  from '@/Components/Global/BasicChart/Components/ChartPie/template.vue'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinComponent from '@/Mixins/MixinComponent'

// Component Extend
const BasicChart = VueMixins(MixinComponent).extend({
	name: 'BasicChart',

	components: {
		ChartBar,
		ChartHBar,
		ChartLine,
		ChartPie
	},

	props: {
		chartData: Object,
		chartType: { type: String, default: 'bar' },
		divide: { type: String, default: '1' },
		footer: String,
		options: Object,
		title: String
	},

	data: function() {
		return {
			states: {
				customHeight: 400,
				widthSidePanel: 280
			}
		}
	},

	beforeMount: function() {
		window.addEventListener('resize', this.resize)
	},

	mounted: function() {
		this.resize()
	},

	beforeDestroy: function() {
		window.removeEventListener('resize', this.resize)
	},

	methods: {
		_getChartComponent: function() {
			return this.$refs[`chart${ this._getChartName() }Component`]
		},

		_getChartName: function() {
			const { chartType } = this.$props
			return `${ chartType.charAt(0).toUpperCase() }${ chartType.slice(1) }`
		},

		render: function() {
			const component: any = this._getChartComponent()
			component.render()
		},

		resize: function() {
			const component: any = this._getChartComponent()
			const { canvas } = component.$refs

			// Altura (this.customHeight).
			canvas.parentNode.style.height = `${ this.states.customHeight }px`

			// Ancho (Espacio Disponible de 'page-wrapper').
			const divideValue = parseInt(this.divide)
			const paddingPageWrapper = 16 * 2
			const offset = (16 * 2) + (8 * 2) + ((8 * 2) * (divideValue - 1)) + (8 * (divideValue - 1))
			const calcWidth = (window.innerWidth - this.states.widthSidePanel - paddingPageWrapper - offset) / divideValue
			canvas.parentNode.style.width = `${ calcWidth }px`
		},

		update: function() {
			const component: any = this._getChartComponent()
			const { _chart } = component.$data
			_chart.update()
		}
	},

	watch: {
		['states.customHeight']: function() {
			this.resize()
		},
		
		['states.widthSidePanel']: function() {
			this.resize()
		}
	}
})

// Exports
export default BasicChart
export type BasicChartRef = InstanceType<typeof BasicChart>