// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Components (.vue)
import SubmitButton from '@/Components/Global/SubmitButton/template.vue'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Component Extend
const AuditCodeForm = VueMixins(MixinComponent, MixinResponsive).extend({
	name: 'AuditCodeForm',

	components: {
		SubmitButton
	},

	props: {
		show: Boolean
	},

	data: function() {
		return {
			states: {
				code: '',
				dateValidation: '',
				isFetching: false
			}
		}
	},

	methods: {
		onACFSubmitClick: function() {
			DevelopmentTools.printWarn('[AttributesList]:onACFSubmitClick() event triggered')
			this.$emit('onACFSubmitClick', { code: this.states.code, dateValidation: this.states.dateValidation })
		}
	}
})

// Exports
export default AuditCodeForm
export type AuditCodeFormRef = InstanceType<typeof AuditCodeForm>