// Classes
import { ActionItem }        from '@/Classes/Records/ActionItem'
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { RecordHelper }      from '@/Classes/Records/RecordHelper'
import { PrimitiveTools }    from '@/Classes/Static/PrimitiveTools'

// Constants
import { ActionFields } from '@/Constants/ActionFields'
import { AppModules }   from '@/Constants/AppModules'
import { AppValues }    from '@/Constants/AppValues'

// Helpers
import { ActionField }    from '@/Helpers/Components/DataTable/ActionField'
import { BuilderManager } from '@/Helpers/Components/DataTable/BuilderManager'

// Aliases
const actionFieldStatus = new ActionField('status', 'Estado')

export function parseAddonsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idCheckListAddons', '_idStorages', 'storages', 'isForClient')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('code', 'Código').isSortable().setAlign(null, 'center')
		.add('name', 'Descripción').isSortable()
		.add('typeEquipment', 'Tipo Equipo').isSortable().setAlign(null, 'center')
		.add('groupArticle', 'Grupo Artículo').isSortable().setAlign(null, 'center')
		.add('attributes', 'Preguntas')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('code', 'name', 'typeEquipment', 'groupArticle', 'attributes', 'isForClient')

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters({
		attributes: (values: Array<string>) => {
			return values.sort()
		},

		groupArticle: (value: string) => {
			return value === AppModules.Modules.CheckLists.TypeActiveNames.FACILITY
				? AppModules.Modules.CheckLists.TypeActiveTranslationNames.FACILITY.toUpperCase()
				: value
		},

		isForClient: (value: boolean) => {
			return value ? 'Sí' : 'No'
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}

export function parseStackedAddonsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idCheckListAddons', 'storages', 'attributes', 'groupArticle', 'isForClient', 'name', 'typeEquipment')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('code', 'Código').isSortable().setAlign(null, 'center').showDetailsButton()
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('code')

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters({
		code: (value: string, item: any) => {
			return `${ value } (${ item.name })`
		}
	})

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail({
		[RecordHelper.setFlags('Descripción', 'name')]: ({ name }) => {
			return name
		},
		[RecordHelper.setFlags('Tipo Equipo', 'typeEquipment')]: ({ typeEquipment }) => {
			return typeEquipment
		},
		[RecordHelper.setFlags('Grupo Artículo', 'groupArticle')]: ({ groupArticle }) => {
			return groupArticle === AppModules.Modules.CheckLists.TypeActiveNames.FACILITY
				? AppModules.Modules.CheckLists.TypeActiveTranslationNames.FACILITY.toUpperCase()
				: groupArticle
		},
		['Preguntas']: ({ attributes }) => {
			return attributes
		},
		[RecordHelper.setFlags('Exclusivo Cliente', 'isForClient')]: ({ isForClient }) => {
			return isForClient ? 'Sí' : 'No'
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}

export function parseCheckListsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, [...ActionFields.SearchPDF, actionFieldStatus])

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idChecklist', '_idCheckListAddons', '_idStorage', '_idOperator', '_idEquipment', 'attributes', 'dataListAddons', 'comment', 'dataSetting', 'companyName', 'storageName', 'dataLocation')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('codeEquipment', 'Código Equipo').isSortable().setAlign('center', 'center')
		.add('nameEquipment', 'Nombre Equipo').isSortable().setAlign('center', 'center').setSearchKey('equip.name')
		.add('groupArticle', 'Grupo Artículo').isSortable().setAlign('center', 'center').setSearchKey('addons.groupArticle')
		.add('hourmeter', 'Horómetro').setAlign('center', 'center')
		.add('addonsCode', 'Cód. Formulario').isSortable().setAlign('center', 'center').setSearchKey('addons.code')
		.add('addonsName', 'Nombre Formulario').isSortable().setAlign('center', 'center').setSearchKey('addons.name')
		.add('operator', 'Responsable').isSortable().setAlign('center', 'center').setSearchKey('user.name')
		.add('zone', 'Sector').isSortable().setAlign('center', 'center').setSearchKey('zone.code')
		.add('date', 'Fecha').setAlign('center', 'center')
		.add('ok', 'OK').setAlign('center', 'center').setStyleTh({ width: '64px' })
		.add('not', 'NO').setAlign('center', 'center').setStyleTh({ width: '64px' })
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('codeEquipment', 'nameEquipment', 'hourmeter', 'groupArticle', 'addonsCode', 'addonsName', 'operator', 'zone', 'date', 'ok', 'not', 'status')

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(new ItemMissingFields()
		.add('addonsName')
		.add('addonsCode')
		.add('groupArticle')
		.add('nameEquipment')
		.add('ok')
		.add('not')
		.add('zone')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters({
		addonsCode: function(v: never, item: any) {
			const { dataListAddons } = item
			if (Array.isArray(dataListAddons) && dataListAddons.length === 0) return AppValues.Strings.DEFAULT_EMPTY_STRING
			const [addons] = dataListAddons
			return addons ? addons.code : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		addonsName: (v: never, item: any) => {
			const { dataListAddons } = item
			if (Array.isArray(dataListAddons) && dataListAddons.length === 0) return AppValues.Strings.DEFAULT_EMPTY_STRING
			const [addons] = dataListAddons
			return addons ? addons.name : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		codeEquipment: (v: string) => {
			return v || AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		date: (v: string) => {
			const _date = PrimitiveTools.Dates.parseDateString(v)
			const date = _date ? `${ _date.date } ${ _date.time }` : ''
			return date || AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		groupArticle: (_: void, item: any) => {
			const [addonsList] = item.dataListAddons
			return addonsList?.groupArticle === AppModules.Modules.CheckLists.TypeActiveNames.FACILITY
				? AppModules.Modules.CheckLists.TypeActiveTranslationNames.FACILITY
				: addonsList?.groupArticle || AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		hourmeter: (v: string) => {
			return v || AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		not: (_: void, item: any) => {
			const { attributes } = item
			return attributes ? attributes?.filter((x: any) => !x.status).length : 0
		},

		ok: (_: void, item: any) => {
			const { attributes } = item
			return attributes ? attributes?.filter((x: any) => x.status).length : 0
		},

		zone: (_: void, item: any) => {
			const { dataLocation } = item
			if (!dataLocation || dataLocation?._idZone === null) return 'SIN SECTOR'
			if (dataLocation?.zoneCode === undefined && dataLocation?.zoneName === undefined) return 'SIN SECTOR'
			return dataLocation.zoneCode
		}
	})

	// Realizar una Ejecución para cada Item.
	bm.forEachItem((item: any) => {
		// El icono de la columna 'status' debe ser agregada despues que los valores de las columnas OK y NO fueron calculados.
		const { not, status } = item
		const isOK = not === 0
		const notPresent = not === -1

		// Definición del icono de la columna.
		const icon = isOK ? 'check-circle' : notPresent ? 'minus-circle' : 'times-circle'
		const variant = isOK ? 'green' : notPresent ? 'gray' : 'red'
		const text = isOK ? 'OK' : notPresent ? AppValues.Strings.DEFAULT_EMPTY_STRING : 'NO'
		const actionItem = new ActionItem('status', 'icon').setIcon(icon).setPermission('ACTION_STATUS').setText(text).setVariant(variant)
		status.push(actionItem)
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}

export function parseStackedCheckListsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, [actionFieldStatus])

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idChecklist', '_idCheckListAddons', '_idEquipment', '_idOperator', '_idStorage', 'attributes', 'comment', 'dataLocation', 'dataListAddons', 'date', 'hourmeter', 'nameEquipment', 'operator', 'dataSetting', 'companyName', 'storageName')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('codeEquipment', 'Código Equipo').isSortable().setAlign(null, 'center').showDetailsButton()
	)

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(new ItemMissingFields()
		.add('ok')
		.add('not')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters({
		codeEquipment: (value: string, item: any) => {
			const [addons] = item.dataListAddons
			const typeEquipment = addons?.typeEquipment === AppModules.Modules.CheckLists.TypeActiveNames.FACILITY ? AppModules.Modules.CheckLists.TypeActiveTranslationNames.FACILITY.toUpperCase() : addons?.typeEquipment
			return typeEquipment && addons?.groupArticle ? `${ value } - ${ addons?.groupArticle } (${ typeEquipment })` : value
		},

		date: (v: string) => {
			const _date = PrimitiveTools.Dates.parseDateString(v)
			const date = _date ? `${ _date.date } ${ _date.time }` : ''
			return date || AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		not: (_: void, item: any) => {
			const { attributes } = item
			return attributes ? attributes?.filter((x: any) => !x.status).length : 0
		},

		ok: (_: void, item: any) => {
			const { attributes } = item
			return attributes ? attributes?.filter((x: any) => x.status).length : 0
		}
	})

	// Realizar una Ejecución para cada Item.
	bm.forEachItem((item: any) => {
		// El icono de la columna 'status' debe ser agregada despues que los valores de las columnas OK y NO fueron calculados.
		const { not, status } = item
		const isOK = not === 0
		const notPresent = not === -1

		// Definición del icono de la columna.
		const icon = isOK ? 'check-circle' : notPresent ? 'minus-circle' : 'times-circle'
		const variant = isOK ? 'green' : notPresent ? 'gray' : 'red'
		const text = isOK ? 'OK' : notPresent ? AppValues.Strings.DEFAULT_EMPTY_STRING : 'NO'
		const actionItem = new ActionItem('status', 'icon').setIcon(icon).setPermission('ACTION_STATUS').setText(text).setVariant(variant)
		status.push(actionItem)
	})

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail({
		[RecordHelper.setFlags('Nombre Equipo', 'nameEquipment', 'equip.name')]: ({ nameEquipment }) => {
			return nameEquipment || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Grupo Artículo', 'groupArticle', 'addons.groupArticle')]: ({ dataListAddons }) => {
			const [addonsList] = dataListAddons
			return addonsList?.groupArticle === AppModules.Modules.CheckLists.TypeActiveNames.FACILITY
				? AppModules.Modules.CheckLists.TypeActiveTranslationNames.FACILITY
				: addonsList?.groupArticle || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Horómetro']: ({ hourmeter }) => {
			if (hourmeter === null) return AppValues.Strings.DEFAULT_EMPTY_STRING
			return !isNaN(hourmeter) ? `${ hourmeter } Hrs.` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Cód. Formulario', 'addonsCode', 'addons.code')]: ({ dataListAddons }) => {
			if (Array.isArray(dataListAddons) && dataListAddons.length === 0) return AppValues.Strings.DEFAULT_EMPTY_STRING
			const [addons] = dataListAddons
			return addons ? addons.code : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Nombre Formulario', 'addonsName', 'addons.name')]: ({ dataListAddons }) => {
			if (Array.isArray(dataListAddons) && dataListAddons.length === 0) return AppValues.Strings.DEFAULT_EMPTY_STRING
			const [addons] = dataListAddons
			return addons ? addons.name : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Responsable', 'operator', 'user.name')]: ({ operator }) => {
			return operator
		},
		[RecordHelper.setFlags('Sector', 'zone')]: ({ dataLocation }) => {
			if (!dataLocation || dataLocation?._idZone === null) return 'SIN SECTOR'
			if (dataLocation?.zoneCode === undefined && dataLocation?.zoneName === undefined) return 'SIN SECTOR'
			return dataLocation.zoneCode
		},
		[RecordHelper.setFlags('Fecha', 'date')]: ({ date }) => {
			return date
		},
		['OK']: ({ ok }) => {
			return ok
		},
		['NO']: ({ not }) => {
			return not
		},
		['Acciones']: ActionFields.SearchPDF
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}

export function parseEquipmentsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, [...ActionFields.SearchPDF, actionFieldStatus])

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('checkList', 'companyName', 'storageName', 'dataListAddons')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('code', 'Código Activo').isSortable().setAlign(null, 'center').setSearchKey('equipment.code')
		.add('groupArticle', 'Grupo Articulo').isSortable().setAlign(null, 'center').setSearchKey('equipment.groupArticle')
		.add('typeEquipment', 'Tipo Equipo').isSortable().setAlign(null, 'center').setSearchKey('equipment.typeEquipment')
		.add('addonsCode', 'Cód. Formulario').isSortable().setAlign(null, 'center').setSearchKey('addons.code')
		.add('operator', 'Responsable').isSortable().setAlign(null, 'center').setSearchKey('responsible.name')
		.add('dateCheckList', 'Fecha').setAlign(null, 'center')
		.add('ok', 'OK').setAlign('center', 'center').setStyleTh({ width: '64px' })
		.add('not', 'NO').setAlign('center', 'center').setStyleTh({ width: '64px' })
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('code', 'groupArticle', 'typeEquipment', 'addonsCode', 'operator', 'dateCheckList', 'ok', 'not', 'status')

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(new ItemMissingFields()
		.add('addonsCode')
		.add('dateCheckList')
		.add('operator')
		.add('ok')
		.add('not')
		.add('typeEquipment')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters({
		addonsCode: (_: void, item: any) => {
			const { dataListAddons } = item
			if (Array.isArray(dataListAddons) && dataListAddons.length === 0) return AppValues.Strings.DEFAULT_EMPTY_STRING
			const [addons] = dataListAddons
			return addons ? addons.code : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		dateCheckList: (_: void, item: any) => {
			const { checkList } = item
			const date = checkList ? checkList.date : null
			const _date = PrimitiveTools.Dates.parseDateString(date)
			return _date ? `${ _date.date } ${ _date.time }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		typeEquipment: (value: string) => {
			return value === AppModules.Modules.CheckLists.TypeActiveNames.FACILITY
				? AppModules.Modules.CheckLists.TypeActiveTranslationNames.FACILITY.toUpperCase()
				: value
		},

		ok: (_: void, item: any) => {
			const { checkList } = item
			return checkList ? checkList.attributes?.filter((x: any) => x.status).length : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		not: (_: void, item: any) => {
			const { checkList } = item
			return checkList ? checkList.attributes?.filter((x: any) => !x.status).length : AppValues.Strings.DEFAULT_EMPTY_STRING
		}
	})

	// Realizar una Ejecución para cada Item.
	bm.forEachItem((item: any) => {
		// El icono de la columna 'status' debe ser agregada despues que los valores de las columnas OK y NO fueron calculados.
		const { not, status } = item
		const isOK = not === 0
		const notPresent = not === AppValues.Strings.DEFAULT_EMPTY_STRING

		// Definición del icono de la columna.
		const icon = isOK ? 'check-circle' : notPresent ? 'minus-circle' : 'times-circle'
		const variant = isOK ? 'green' : notPresent ? 'gray' : 'red'
		const text = isOK ? 'OK' : notPresent ? AppValues.Strings.DEFAULT_EMPTY_STRING : 'NO'
		const actionItem = new ActionItem('status', 'icon').setIcon(icon).setPermission('ACTION_STATUS').setText(text).setVariant(variant)
		status.push(actionItem)
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}

export function parseStackedEquipmentsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, [actionFieldStatus])

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('checkList', 'operator', 'groupArticle', 'typeEquipment', 'companyName', 'storageName', 'dataListAddons')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('code', 'Equipo').isSortable().setAlign(null, 'center').setSearchKey('equipment.code').showDetailsButton()
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('code')

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters({
		code: (value: string, item: any) => {
			const typeEquipment = item.typeEquipment === AppModules.Modules.CheckLists.TypeActiveNames.FACILITY ? AppModules.Modules.CheckLists.TypeActiveTranslationNames.FACILITY.toUpperCase() : item.typeEquipment
			return `${ value } - ${ item.groupArticle } (${ typeEquipment })`
		}
	})

	// Realizar una Ejecución para cada Item.
	bm.forEachItem((item: any) => {
		// El icono de la columna 'status' debe ser agregada despues que los valores de las columnas OK y NO fueron calculados.
		const { not, status } = item
		const isOK = not === 0
		const notPresent = not === AppValues.Strings.DEFAULT_EMPTY_STRING

		// Definición del icono de la columna.
		const icon = isOK ? 'check-circle' : notPresent ? 'minus-circle' : 'times-circle'
		const variant = isOK ? 'green' : notPresent ? 'gray' : 'red'
		const text = isOK ? 'OK' : notPresent ? AppValues.Strings.DEFAULT_EMPTY_STRING : 'NO'
		const actionItem = new ActionItem('status', 'icon').setIcon(icon).setPermission('ACTION_STATUS').setText(text).setVariant(variant)
		status.push(actionItem)
	})

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail({
		[RecordHelper.setFlags('Grupo Artículo', 'groupArticle', 'equipment.groupArticle')]: ({ groupArticle }) => {
			return groupArticle
		},
		[RecordHelper.setFlags('Tipo Equipo', 'typeEquipment', 'equipment.typeEquipment')]: ({ typeEquipment }) => {
			return typeEquipment
		},
		[RecordHelper.setFlags('Cód. Formulario', 'addonsCode', 'addons.code')]: ({ dataListAddons }) => {
			if (Array.isArray(dataListAddons) && dataListAddons.length === 0) return AppValues.Strings.DEFAULT_EMPTY_STRING
			const [addons] = dataListAddons
			return addons ? addons.code : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Responsable', 'operator', 'responsible.name')]: ({ operator }) => {
			return operator
		},
		['Fecha']: ({ checkList }) => {
			const date = checkList ? checkList.date : null
			const _date = PrimitiveTools.Dates.parseDateString(date)
			return _date ? `${ _date.date } ${ _date.time }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['OK']: ({ checkList }) => {
			return checkList ? checkList.attributes?.filter((x: any) => x.status).length : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['NO']: ({ checkList }) => {
			return checkList ? checkList.attributes?.filter((x: any) => !x.status).length : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Acciones']: ActionFields.SearchPDF
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}